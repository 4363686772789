import classNames from 'classnames';
import React from 'react';
import styles from './Spacing.module.scss';

type spacingValue = number | 'xs' | 'sm' | 'md' | undefined;

interface SpacingProps {
  inline?: boolean;
  top?: spacingValue;
  right?: spacingValue;
  bottom?: spacingValue;
  left?: spacingValue;
  vertical?: spacingValue;
  horizontal?: spacingValue;
  all?: spacingValue;
  children: React.ReactNode;
}

const firstNonNull = (...args: spacingValue[]): spacingValue | undefined =>
  args.find((arg) => !!arg);

const spacingClassName = (
  side?: string,
  spacing?: spacingValue,
): string | null => {
  if (!spacing) {
    return null;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const key = `padding${side}${spacing}`;

  return styles[key];
};

const Spacing: React.SFC<SpacingProps> = ({
  top,
  right,
  bottom,
  left,
  vertical,
  horizontal,
  all,
  children,
  inline,
}) => {
  const topSpacing = firstNonNull(top, vertical, all);
  const rightSpacing = firstNonNull(right, horizontal, all);
  const bottomSpacing = firstNonNull(bottom, vertical, all);
  const leftSpacing = firstNonNull(left, horizontal, all);

  const classes = classNames(
    spacingClassName('Top', topSpacing),
    spacingClassName('Right', rightSpacing),
    spacingClassName('Bottom', bottomSpacing),
    spacingClassName('Left', leftSpacing),
    { [styles.inline]: inline },
  );

  return <div className={classes}>{children}</div>;
};
export default Spacing;
