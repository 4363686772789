import { Router } from '@reach/router';
import React from 'react';
import Layout from '../../components/layout/Layout';
import RegisterFlow from '../../components/register/RegisterFlow';

const RegisterPage = () => (
  <Layout>
    <Router>
      <RegisterFlow path="/register/" />
      <RegisterFlow path="/register/:referral/" />
    </Router>
  </Layout>
);

export default RegisterPage;
