import classNames from 'classnames';
import React from 'react';
import styles from './Text.module.scss';

interface TextProps {
  bold?: boolean;
  caps?: boolean;
  center?: boolean;
  children: React.ReactNode;
  inline?: boolean;
  link?: boolean;
  large?: boolean;
  medium?: boolean;
  muted?: boolean;
  semiBold?: boolean;
  small?: boolean;
  underline?: boolean;
  heavyUnderline?: boolean;
  xSmall?: boolean;
  xxSmall?: boolean;
  darker?: boolean;
  white?: boolean;
  primary?: boolean;
}

const Text: React.SFC<TextProps> = ({
  bold,
  caps,
  center,
  children,
  inline,
  link,
  large,
  medium,
  muted,
  semiBold,
  small,
  underline,
  heavyUnderline,
  xSmall,
  xxSmall,
  darker,
  white,
  primary,
}) => (
  <div
    className={classNames(styles.text, {
      [styles.bold]: bold,
      [styles.caps]: caps,
      [styles.center]: center,
      [styles.inline]: inline,
      [styles.link]: link,
      [styles.muted]: muted,
      [styles.semiBold]: semiBold,
      [styles.small]: small,
      [styles.underline]: underline,
      [styles.heavyUnderline]: heavyUnderline,
      [styles.large]: large,
      [styles.medium]: medium,
      [styles.xSmall]: xSmall,
      [styles.xxSmall]: xxSmall,
      [styles.darker]: darker,
      [styles.white]: white,
      [styles.primary]: primary,
    })}
  >
    {children}
  </div>
);

export default Text;
