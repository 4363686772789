import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { AccentColors } from '../../types';
import wrapTrack from '../../utils/wrapTrack';
import styles from './Button.module.scss';

export interface ButtonProps {
  block?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  light?: boolean;
  link?: boolean;
  linkUnderline?: boolean;
  muted?: boolean;
  onClick?: React.MouseEventHandler;
  target?: string;
  primary?: boolean;
  secondary?: boolean;
  short?: boolean;
  small?: boolean;
  to?: string;
  type?: 'button' | 'submit';
  unstyled?: boolean;
  color?: AccentColors;
  track?: { label: string; platform: string };
}

const Button: React.SFC<ButtonProps> = ({
  block,
  children,
  disabled,
  light,
  linkUnderline,
  link,
  muted,
  onClick: originalOnClick,
  primary,
  secondary,
  short,
  small,
  target,
  to,
  type = 'button',
  unstyled,
  color,
  track,
}) => {
  const classes = classNames(styles.button, color && styles[color], {
    [styles.block]: block,
    [styles.disabled]: disabled,
    [styles.light]: light,
    [styles.link]: link || linkUnderline,
    [styles.underline]: linkUnderline,
    [styles.muted]: muted,
    [styles.primary]: primary,
    [styles.secondary]: secondary,
    [styles.short]: short,
    [styles.small]: small,
    [styles.unstyled]: unstyled,
  });

  const onClick = wrapTrack(track, originalOnClick);

  if (to) {
    return to.match(/^(https?|mailto):/) || to.match(/^#/) || target ? (
      <a className={classes} href={to} target={target} onClick={onClick}>
        {children}
      </a>
    ) : (
      <Link className={classes} to={to} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <button
      className={classes}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
